.App {
  text-align: center;
}

.MainContainer {
  background-color: gainsboro;
  padding-top: 64px;
  position: absolute;
  top: 0;
  overflow: auto;

  > div {
    height: 100%;
  }
}

.MainNavBar {
  z-index: 1001;
}

.CurrentSpeaker {
  text-align: center;
  padding-top: 1em;
}

.ControlButton {
  margin: 0.15em;
}

.ButtonWithConfirm {
  button {
    @extend .ControlButton;
  }
}

.SpeakersList {
  ul {
    list-style-type: none;
    padding-inline-start: 10px;
  }

  padding-top: 0.5em;
}

.LoadingContainer {
  text-align: center;
}

.ActionIcon {
  color: gray;
  margin-left: 0.25em;

  :hover {
    color: black;
  }
}

.form-inline {
  display: block;
}

.min-height-35 {
  min-height: 35%;
}

.Timer-Edit-Icon {
  svg {
    margin-left: 0.25em;
    font-size: 50%;
    position: absolute;
  }
  h1 {
    display: inline-block;
    margin-bottom: 0;
    cursor: pointer;
  }
  @extend .Timer-Display;
  justify-content: left;
  align-items: center;
  font-size: 50%;
}

.Timer-Delete-Icon {
  svg {
    margin-left: 0.25em;
    font-size: 50%;
    position: absolute;
  }
  h1 {
    display: inline-block;
    margin-bottom: 1em;
    cursor: pointer;
  }
  @extend .Timer-Display;
  justify-content: left;
  align-items: center;
  font-size: 50%;
}

.Timer-Value {
  cursor: pointer;
  justify-content: center;
  flex-basis: auto;
}

.Timer-Display {
  flex: 1;
  display: flex;
}

.Timer-Value-Container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Multiple-Timer-Container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.Single-Timer-Container {
  display: inline;
  align-items: center;
  justify-content: center;
  margin-right: 2em;
}

.Edit-List-Name-Icon {
  font-size: 75%;
  margin-left: 0.2em;
}

.Edit-List-Container {
  .form-control {
    margin-left: 0.25em;
    display: inline-block;
    width: auto;
  }
  button {
    margin-left: 0.25em;
  }
}

.Nav-Settings-Icon {
  color: #fff;
  cursor: pointer;
  :hover {
    color: grey;
  }
}

.Settings-Container {
  margin-top: 0.5em;
}

.Settings-Column {
  margin-top: 0.5em;
}
